import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SeoService } from '../../service/seo.service';
import { DataService } from '../../service/data.service';

@Component({
  selector: 'rs-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private seo: SeoService) {
    this.seo.generateTags({
      title: 'About | Maharaja Sweets - Authentic Indian cuisine and catering | Weddings,  Parties, Corporate Events',
      description: 'Maharaja Sweets has two locations in San Francisco Bay area that are serving over 20 years. We Specialize in Indian food catering for small to large groups any where in California. Find out for yourself why over and over again countless people chose to have their special events Catered by us. The reasons are endless: Our fine cuisine, on site Tandoor for fresh nan, our Indian style unique decor stalls, our expertly trained chefs, friendly and knowledgeable staff, as well as our service.',
      slug: '/about'
    });
  }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private contactInfo = {
    businessName: 'Maharaja Sweets & Catering',
    businessName2: 'Raja Indian Cuisine',
    fullAddress: '31853 Alvarado Blvd, Union City, CA 94587',
    address: {
      street: '31853 Alvarado Blvd',
      city: 'Union City',
      state: 'CA',
      zip: 94587
    },
    hours: [
      '<b>Mon-Sat: Appointments only</b>',
      '<b>Sunday: 10am - 5pm</b>'
    ],
    phone: '(510) 489-9100',
    fax: '(510) 489-9111',
    email: {
      primary: 'msbains@rajasweets.com',
      secondary: 'msbains@rajasweets.com',
    },
    website: 'www.rajasweets.com',
    locations: [
      {
        logo: '../../assets/raja-sweets-and-cuisine-logo.png',
        name:  'Maharaja Sweets & Indian Cuisine',
        phone: '(510) 489-9100',
        fax: '(510) 489-9111',
        fullAddress: '31853 Alvarado Blvd, Union City, CA 94587',
        address: {
          street: '31853 Alvarado Blvd',
          city: 'Union City',
          state: 'CA',
          zip: 94587
        },
        hours: [
          '<b>Mon-Sun:	10AM–10PM</b>',
          '&nbsp;'
        ],
        gmap: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3161.506038114565!2d-122.07321124826183!3d37.59024777969436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f95e8420e19dd%3A0xfc656aaf1e937541!2sRaja%20Sweets%20%26%20Indian%20Cuisine!5e0!3m2!1sen!2sus!4v1582081215402!5m2!1sen!2sus'
      },
      {
        logo: '../../assets/raja-indian-cuisine-logo.png',
        name:  'Raja Indian Cuisine',
        phone: '(510) 264-9300',
        fax: '',
        fullAddress: '1275 Winton Avenue, Hayward, CA 94545',
        address: {
          street: '1275 Winton Avenue',
          city: 'Hayward',
          state: 'CA',
          zip: 94545
        },
        hours: [
          '<b>Tue-Sun: 10AM – 9PM</b>',
          '<b>Mon:	Closed</b>'
        ],
        gmap: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50550.256149356246!2d-122.13050892533715!3d37.64001045994921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f913d0641dbaf%3A0x8966f332c419919a!2sRaja%20Indian%20Cuisine!5e0!3m2!1sen!2sus!4v1582081295012!5m2!1sen!2sus'
      }
    ]
  };

  private menu = [
    { name: 'Appetizers', id: 'Appetizers', desc: 'Served with zesty sweet & sour tamarind sauce and mint and cilantro chutney',
      items: [
        this._createMenuItem('Veg Puff', '', 2.99),
        this._createMenuItem('Kachori', '', 2.99),
        this._createMenuItem('Fish Pakora', 'Boneless fish pieces dipped in batter and fried', 5.99),
        this._createMenuItem('Meat Samosa (2)', 'Crisp patties stuffed with minced lamb and peas with aromatic spices', 5.99),
        this._createMenuItem('Chicken Pakora', 'Boneless chicken pieces dipped in batter and fried', 6.99),
        this._createMenuItem('Non-Vegetable Assorted', 'Assortment of barbecue specialities-boti kabab, tikka chicken, seekh kabab' +
          ' chicken and papadum', 10.99),
        this._createMenuItem('Papadum (1)', 'Crispy spiced lentil indian wafer', 0.99),
        this._createMenuItem('Mix Pakora', 'An assortment of fresh vegetables dipped in batter and fried', 4.99),
        this._createMenuItem('Tawa Tikki (2)', 'Potato patties, spiced and deep fried', 4.99),
        this._createMenuItem('Vegetable Samosa', 'Crispy patties stuffed with spiced potatoes and green peas', 4.99),
        this._createMenuItem('Samosa Chaat', 'Crispy potato stuffed patties served with yougurt and chutney', 5.99),
        this._createMenuItem('Chole Bhature', 'Garbanzo beans & deep fried whole wheat bread', 7.99),
        this._createMenuItem('Vegetable Assorted', 'Assortment of samosas, pakora and papadum', 6.99),
        this._createMenuItem('Paneer Tikka', 'Homemade cheese marinated overnight in spices & baked in clay oven', 7.99),
        this._createMenuItem('Vegetable Cutlet (2)', 'Mixed vegetable patties spiced and deep fried', 3.99),
        this._createMenuItem('Chana Samosa', 'Crispy patties stuffed with garbanzos', 6.99),
        this._createMenuItem('Paneer Pakora', 'Homemade cheese, deep fried', 5.99),
        this._createMenuItem('Chana Tikki', 'Potato patties with garbanzo beans', 5.99),
        this._createMenuItem('Bharwa Paneer Pakora', '', 6.99),
        this._createMenuItem('Bread Pakora (2)', '', 4.99),
        this._createMenuItem('Veg Manchurian', '', 8.99)
      ]
    },
    { name: 'Soup & Salad', id: 'SoupSalad', desc: '',
      items: [
        this._createMenuItem('Dal Soup', 'Spicy lentil soup', 4.99),
        this._createMenuItem('Mulligatawny Soup', 'A delicious lentil soup made with chicken, herbs and indian spices', 5.99),
        this._createMenuItem('Tomato Soup', 'Cream of tomatoes', 4.99),
        this._createMenuItem('Chicken Soup', 'Delicious chicken soup made from chicken pieces', 4.99),
        this._createMenuItem('Green Salad', 'Fresh salad with home-made dressing', 4.99)
      ]
    },
    { name: 'Tandoori Special', id: 'TandooriSpecial', desc: 'Prepared in Indian tradition. Cooked on skewers in tandoor & served' +
        ' sizzling hot with salted onions. All a la carte items served with your choice of nan or rice',
      items: [
        this._createMenuItem('Tandoori Chicken 4pcs', 'Chicken marinated in spicy yogurt, garlic, ginger & herbs', 7.99),
        this._createMenuItem('Tandoori Chicken 8pcs', 'Chicken marinated in spicy yogurt, garlic, ginger & herbs', 11.99),
        this._createMenuItem('Chicken Tikka', 'Chicken cubes marinated in spices & baked in clay oven', 10.99),
        this._createMenuItem('Tandoori Fish', 'Cubes of fish marinated in yogurt & spices', 11.99),
        this._createMenuItem('Seekh Kabab', 'Minced lamb blended with spices, herbs & cooked on skewers', 11.99),
        this._createMenuItem('Boti Kabab', 'Boneless lamb marinated in spices & baked in tandoori oven', 11.99),
        this._createMenuItem('Raja Tandoori Mixed Grill', 'Tandoori shrimp, boti kabab, chicken, seekh kabab & chicken tikka', 14.99)
      ]
    },
    { name: 'Non-Vegetarian Dishes', id: 'NonVegetarianDishes', desc: 'A la carte items are served with rice or nan',
      items: [
        this._createMenuItem('Lamb Vindaloo', 'Lamb cooked in hot, spicy sauce with potatoes', 11.99),
        this._createMenuItem('Lamb Curry', 'Lamb cooked in spices with herbs and onions', 11.99),
        this._createMenuItem('Lamb Punjabi', 'Lamb in hot, spicy sauce with garlic, ginger and spices', 11.99),
        this._createMenuItem('Lamb Saagwala', 'Lamb cooked with spiced creamed spinach', 11.99),
        this._createMenuItem('Rogan Josh', 'Lamb cooked with onion and spices', 11.99),
        this._createMenuItem('Lamb Karahi', 'Lamb cooked with bell pepper, onion, tomatoes and spices', 11.99),
        this._createMenuItem('Keema Mattar', 'Minced lamb with green peas', 11.99),
        this._createMenuItem('Chicken Curry', 'Chicken pieces in tomatoes, onion sauce and crushed cashews', 9.99),
        this._createMenuItem('Goat Curry', 'Goat meat in tomatoes, onion sauce and crushed cashews', 10.99),
        this._createMenuItem('Chicken Makhanwala (Butter Chicken)', 'Boneless chicken marinated in ginger, garlic, yogurt and spices', 9.99),
        this._createMenuItem('Chicken Korma', 'Boneless chicken cooked in cheese, cream, cashew & gravy', 9.99),
        this._createMenuItem('Chicken Saagwala', 'Chicken cooked with spiced cream spinach', 9.99),
        this._createMenuItem('Chicken Do Piaza', 'Chicken cooked in spices with herbs and onions', 9.99),
        this._createMenuItem('Chicken Vindaloo', 'Chicken cooked in hot, spicy sauce with potatoes', 9.99),
        this._createMenuItem('Chicken Tikka Masala', 'Tandoori chicken tikka cooked in creamy sauce', 9.99),
        this._createMenuItem('Chicken Chili', 'Chicken, onion, bell peppers, chili and spices', 9.99),
        this._createMenuItem('Chicken Mushroom', 'Chicken, Mushroom, ginger, garlic and green pepper', 9.99),
        this._createMenuItem('Egg Bhurji', 'Scrambled eggs', 9.99)
      ]
    },
    { name: 'Succulent Vegetarian Dishes', id: 'SucculentVegetarianDishes', desc: 'A la carte items are served with rice or nan',
      items: [
        this._createMenuItem('Aloo Palak', 'Potato and spinach cooked in herbs and spices', 7.99),
        this._createMenuItem('Paneer Bhurji', 'Homemade cheese cooked in herbs and spices', 9.99),
        this._createMenuItem('Saag Paneer', 'Fresh spinach cooked with herbs and homemade cheese', 8.99),
        this._createMenuItem('Mattar Paneer', 'Cubes of homemade cheese and peas in flavorful gravy', 8.99),
        this._createMenuItem('Palak Paneer', 'Fresh spinach cooked in herbs and spices', 8.99),
        this._createMenuItem('Karahi Paneer', 'Rich cream cooked with cashews, pistachios, herbs and spices', 8.99),
        this._createMenuItem('Shahi Paneer', 'Homemade cheese cooked in creamy gravy with herbs & spices', 8.99),
        this._createMenuItem('Paneer Tikka Masala', 'Homemade cheese cooked in creamy sauce with herbs and spices', 8.99),
        this._createMenuItem('Malai Kofta', 'Mixed vegetables and cottage cheese balls cooked in butter sauce', 7.99),
        this._createMenuItem('Chana Masala', 'Garbanzo beans in a special blend of spices', 7.99),
        this._createMenuItem('Aloo Ghobi Masala', 'Cauliflower and potatoes cooked with herbs and spices', 7.99),
        this._createMenuItem('Navrattan Korma', 'Vegetables with homemade cheese and nuts in a mild cream sauce', 7.99),
        this._createMenuItem('Baingan Bhartha', 'Eggplant baked in a clay oven and cooked with onions ,tomatoes and spices', 7.99),
        this._createMenuItem('Bhindi Masala', 'Okra sauteed with onion, tomato cilantro and spices', 7.99),
        this._createMenuItem('Dal Makhani', 'Creamed lentils in delicately spiced cream sauce', 7.99),
        this._createMenuItem('Dum Aloo', 'Small whole potatoes in thick gravy', 7.99),
        this._createMenuItem('Mushroom Mattar', 'Mushroom and green peas in spices, herbs and gravy', 7.99),
        this._createMenuItem('Cheese Chili', 'Home made cheese with Bell pepper and onion', 8.99),
        this._createMenuItem('Gobhi Manchurian', 'Cauliflower with manchoorian gravy', 8.99),
        this._createMenuItem('Aloo Mattar', 'Potatoes and peas', 7.99),
        this._createMenuItem('Mixed Vegetable', 'Mixed vegetable cooked with onion, ginger, garlic with herbs and spices', 7.99),
        this._createMenuItem('Yellow Dal', '', 7.99),
        this._createMenuItem('Paneer Dil Bahaar', '', 9.99),
      ]
    },
    { name: 'Punjabi Specialities', id: 'PunjabiSpecialities', desc: '',
      items: [
        this._createMenuItem('Punjabi Karhi', 'Yogurt, chana besan, ginger, garlic', 7.99),
        this._createMenuItem('Makki Di Roti With Saag', 'Corn flour bread with spinach cooked in Punjabi style', 9.99),
        this._createMenuItem('Papdi Chaat', 'Puffed pastries with an assortment of chutneys', 5.99),
        this._createMenuItem('Dahi Bhalla', 'Soft lentil balls in seasoned yogurt & tamarind & mint chutney', 3.99)
      ]
    },
    { name: 'Delicious Seafood', id: 'DeliciousSeafood', desc: '',
      items: [
        this._createMenuItem('Fish Curry', 'Boneless fish cubes in spicy gravy', 11.99),
        this._createMenuItem('Shrimp Curry', 'Shrimp cooked with bell peppers & spices', 12.99),
        this._createMenuItem('Shrimp Masala', 'Shrimp cooked in fresh spinach & spices', 12.99),
        this._createMenuItem('Fish Masala', 'Fish cubes cooked with onions, bell peppers, tomatoes & spices', 11.99)
      ]
    },
    { name: 'Raja\'s Special Thaali', id: 'RajasSpecialThaali', desc: '',
      items: [
        this._createMenuItem('Vegetarian Thaali', 'One vegetable entry of your choice served with nan, rice, dal, raita and dessert of' +
          ' the day. Start with vegetable samosa, papadum as an appetizer.', 24.99),
        this._createMenuItem('Non-vegetarian Thaali', 'One chicken or lamb entry of your choice served with nan, rice, dal, raita and' +
          ' dessert of the day. Start with chicken tikka, seekh kabab and papadum as an appetizer.', 29.99)
      ]
    },
    { name: 'Indian Breads', id: 'IndianBreads', desc: 'Breads are freshly made in clay-oven',
      items: [
        this._createMenuItem('Tandoori Chapati', 'Leavened whole wheat flour bread baked in tandoor', 0.99),
        this._createMenuItem('Aloo Paratha', 'Leavened whole wheat bread with spiced potatoes & peas', 3.99),
        this._createMenuItem('Gobi Paratha', 'Leavened whole wheat bread with spiced cauliflower', 4.99),
        this._createMenuItem('Muli Paratha', 'Leavened whole wheat bread with spiced diakon', 4.99),
        this._createMenuItem('Paneer Paratha', 'Nan stuffed with home-made cheese', 4.99),
        this._createMenuItem('Poori (2)', 'Deep-fried whole wheat bread', 2.99),
        this._createMenuItem('Nan', 'Leavened flour bread', 0.99),
        this._createMenuItem('Garlic Nan', 'Bread stuffed with garlic and spices', 1.99),
        this._createMenuItem('Keema Nan', 'Nan stuffed with minced lamb and spices', 3.99),
        this._createMenuItem('Raja\'s Special Nan', 'Nan stuffed with nuts, raisins, coconut & cherries', 3.99),
        this._createMenuItem('Chicken Nan', 'Nan stuffed with spiced bits of chicken', 3.99),
        this._createMenuItem('Onion Kulcha', 'Leavened flour bread stuffed with spiced onions', 2.99),
        this._createMenuItem('Assorted Bread Basket', 'Plain nan, plain paratha, garlic nan, tandoori roti', 6.99),
        this._createMenuItem('Plain Paratha or Lachha Paratha', '', 2.99)
      ]
    },
    { name: 'Biryani', id: 'Biryani', desc: '',
      items: [
        this._createMenuItem('Raja Special Biryani', 'Saffron flavored basmati rice w/ chicken, Lamb, Shrimps, nuts & raisins', 11.99),
        this._createMenuItem('Chicken Biryani', 'Saffron flavored basmati rice w/spiced chicken, nuts & raisins', 8.99),
        this._createMenuItem('Lamb Biryani', 'Saffron flavored basmati rice w/ spiced lamb', 9.99),
        this._createMenuItem('Vegetable Biryani', 'Saffron flavored basmati rice w/ vegetables, nuts & cheese', 7.99),
        this._createMenuItem('Pulao Rice', 'Basmati rice fried with herbs, spices and green peas', 3.99),
        this._createMenuItem('Plain Rice', '', 2.99)
      ]
    },
    { name: 'Desserts', id: 'Desserts', desc: '',
      items: [
        this._createMenuItem('Jamun (2)', '', 2.99),
        this._createMenuItem('Gajar Halwa', '', 3.99),
        this._createMenuItem('Julie (2)', '', 2.99),
        this._createMenuItem('Rasmalai (2)', '', 3.99)
      ]
    },
    { name: 'Beverages', id: 'Beverages', desc: '',
      items: [
        this._createMenuItem('Mango Lassi', '', 2.99),
        this._createMenuItem('Lassi (Sweet or Salted)', '', 2.50),
        this._createMenuItem('Indian Tea', '', 1.99),
        this._createMenuItem('Soft Drinks', '', 1.49),
        this._createMenuItem('Indian Beer (Large)', '', 7.00),
        this._createMenuItem('Indian Beer (Small)', '', 5.00),
        this._createMenuItem('American Beer', 'Budweiser Beer, Corona, Coors', 5.00)
      ]
    },
  ];

  private slides = [
    '../../assets/slides/01.jpg',
    '../../assets/slides/02.jpg',
    '../../assets/slides/03.jpg',
    '../../assets/slides/04.jpg',
    '../../assets/slides/05.jpg',
    '../../assets/slides/06.jpg',
    '../../assets/slides/07.jpg',
    '../../assets/slides/08.jpg'
  ];

  private cateringPackages = [
    this._createCateringPackage('veggie', 'Veggie Package', [1, 1, 1], [2, 1], 1, 11),
    this._createCateringPackage('silver', 'Silver Package', [1, 1, 1], [2, 1], 1, 13),
    this._createCateringPackage('gold', 'Gold Package', [2, 1, 1], [2, 2], 1, 16),
    this._createCateringPackage('diamond', 'Diamond Package', [2, 2, 2], [2, 2], 1, 19)
  ];

  private cateringAutoComplete = {
    vegAppetizers : [
      'Mix Veg. Pakora',
      'Aloo Tikki',
      'Veg. Samosa',
      'Paneer Tikka',
      'Veg. Cutlet',
      'Veg. Spring Rolls',
      'Veg. Sandwich',
      'Paneer Pakora'
    ],
    nonVegAppetizers : [
      'Chicken Pakora',
      'Tandoori Chicken',
      'Chicken Tikka',
      'Chili Chicken',
      'Seekh Kabab',
      'Fish Pakora ($2 extra)',
      'Shrimp Chili ($3 extra)',
    ],
    sweetAppetizers : [
      'Gulab Jamun',
      'Chum Chum',
      'Rasgulla',
      'Barfi',
      'Ras Malai',
      'Kheer',
      'Gajar Halwa',
      'Moong Dal Halwa',
      'Kulfi'
    ],
    vegEntrees : [
      'Aloo Palak',
      'Paneer Bhurji',
      'Saag Paneer',
      'Mattar Paneer',
      'Palak Paneer',
      'Shahi Paneer',
      'Paneer Tikka Masala',
      'Chili Paneer',
      'Malai Kofta',
      'Chana Masala',
      'Aloo Gobhi Masala',
      'Navrattan Korma',
      'Shahi Korma',
      'Baingan Bhartha',
      'Bhindi Masala',
      'Dal Makhani',
      'Dum Aloo',
      'Mushroom Mattar',
      'Cheese Tomato',
      'Keema Gobhi',
      'Gobhi Manchurian',
      'Aloo Mattar',
      'Mix Vegetable',
      'Punjabi Kadhi Pakora',
      'Tawa Fry Vegetable ($3 extra)',
    ],
    nonVegEntrees : [
      'Lamb Shahi',
      'Lamb Vindaloo',
      'Lamb Do Piaza',
      'Lamb Punjabi',
      'Rogan Josh / Lamb Curry',
      'Lamb Karahi',
      'Keema Mattar',
      'Goat Curry',
      'Chicken Curry',
      'Butter Chicken',
      'Chicken Tikka Masala',
      'Chicken Korma',
      'Chicken Mushroom',
      'Chicken Do Piaza',
      'Chicken Vindaloo',
      'Chicken Chili',
      'Chicken Zeera'
    ],
    desserts : [
      'Gulab Jamun',
      'Chum Chum',
      'Rasgulla',
      'Barfi',
      'Ras Malai',
      'Kheer',
      'Gajar Halwa',
      'Moong Dal Halwa',
      'Kulfi'
    ]
  };

  constructor() { }

  getCateringAutoComplete() {
    return this.cateringAutoComplete;
  }

  getContactInfo() {
    return this.contactInfo;
  }

  getMenu() {
    return this.menu;
  }

  getSlides() {
    return this.slides;
  }

  getCateringPackages() {
    return this.cateringPackages;
  }

  private _createMenuItem(name, desc, price) {
    return { name, desc, price };
  }

  private _createCateringPackage(id, name, appetizers, entrees, dessert, price) {
    return {
      id,
      name,
      price,
      appetizers: {
        veg: appetizers[0] || 0,
        nonVeg: appetizers[1] || 0,
        sweet: appetizers[2] || 0
      },
      entrees: {
        veg: entrees[0] || 0,
        nonVeg: entrees[1] || 0
      },
      dessert: dessert || 0
    };
  }
}

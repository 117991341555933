<section class="page-header py-3"
         style="background: url('../../../assets/menu.jpg')">
  <div class="content">
    <h1 class="title">Menu</h1>
    <h4 class="description">A Traditional Indian Food Experience</h4>
  </div>
</section>

<section class="container page-content">
  <div class="bg-white clearfix rounded p-3 shadow">
    <div class="row my-5" *ngFor="let item of menu">
      <div class="col-12 menu-header-container">
        <h2 class="text-center menu-header">{{ item.name }}</h2>
      </div>

      <div class="col-sm-6 col-12" *ngFor="let menuItem of item.items">
        <div class="menu-item">
          <div class="price-container">
            <span class="menu-item-name">
              {{ menuItem.name }}
            </span>
            <span class="menu-item-price">
              ${{ menuItem.price }}
            </span>
          </div>
          <p class="menu-item-description ng-binding ng-scope" *ngIf="menuItem.desc">
            {{ menuItem.desc }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

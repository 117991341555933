import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SeoService } from '../../service/seo.service';
import { DataService } from '../../service/data.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'rs-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  formLoading = false;
  contact;
  notification = {
    message : '',
    class   : ''
  };

  contactForm = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    tel: ['', [Validators.required]],
    message: ['', [Validators.required]],
  });

  constructor(private fb: FormBuilder, private http: HttpClient, private seo: SeoService, private data: DataService) {
    this.contact = this.data.getContactInfo();
    this.seo.generateTags({
      title: 'Contact | Maharaja Sweets - Authentic Indian cuisine and catering | Weddings,  Parties, Corporate Events',
      description: 'We welcome your inquiries and are happy to help our clients in all phases of planning their special wedding, event, party and gathering!',
      slug: '/contact'
    });
  }


  ngOnInit() {}

  contactFormSubmit(value) {
    this.formLoading = true;
    this.notification = {
      message : '',
      class   : ''
    };

    this.http.post('/api/v1/contact', value)
      .subscribe((res: any) => {
      if (res.success) {
        this.notification.message = 'Thank you for reaching out. We will contact you as soon as possible.';
        this.notification.class = 'alert-success';

        this.contactForm.reset();
      } else {
        this.notification.message = 'Oops. Looks like something went wrong. Please try again one more time.';
        this.notification.class = 'alert-danger';
      }
      this.formLoading = false;
    });
  }
}

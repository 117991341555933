<section class="page-header py-3"
         style="background: url('../../../assets/about.jpg')">
  <div class="content">
    <h1 class="title">Contact Us</h1>
  </div>
</section>

<section class="container page-content mb-4">
  <div class="shadow-sm bg-white p-3 rounded">
    <div class="py-2">
      <h3 class="p-3 text-tertiary">At Maharaja Sweets & Catering, we can provide you food, china & silverware, linen, waiters and we are fully licenced to serve hard liquor with full bar service anywhere in California. We want to make sure that you and your guests are well taken care of and treated in a courteous and professional manner. We want your guests to have best experience of their life when they come to your event.</h3>
    </div>
    <hr>
    <div class="row d-flex flex-row flex-wrap mx-0 mb-4 pb-4">
      <div class="d-flex align-items-start flex-column col-lg-8 col-md-8 col-sm-8 col-12 mt-3 mb-3 text-left">
        <div class="text-all-dark">
          <h3 class="text-400">Please use the contact form below. We would love to hear from you and help you in any way we can.</h3>
        </div>
        <div class="alert" *ngIf="notification.message"
             [ngClass]="notification.class">
          {{ notification.message }}
        </div>
        <form class="w-100" [formGroup]="contactForm" (ngSubmit)="contactFormSubmit(contactForm.value)">
          <div class="form-group">
            <label for="name" class="text-all-dark">Name</label>
            <input type="text" name="name" id="name"
                   formControlName="name"
                   class="form-control" required>
            <div class="text-danger p-0"
                 *ngIf="!contactForm.controls['name'].valid && contactForm.controls['name'].touched">
              <span *ngIf="contactForm.controls['name'].errors?.required">Please enter your Name.</span>
            </div>
          </div>
          <div class="form-group">
            <label for="email" class="text-all-dark">Email</label>
            <input type="email" name="email" id="email"
                   formControlName="email"
                   class="form-control" required>
            <div class="text-danger p-0"
                 *ngIf="!contactForm.controls['email'].valid && contactForm.controls['email'].touched">
              <span *ngIf="contactForm.controls['email'].errors?.required">Please enter your Email.</span>
              <span *ngIf="contactForm.controls['email'].errors?.email">Please enter valid Email.</span>
            </div>
          </div>
          <div class="form-group">
            <label for="tel" class="text-all-dark">Phone</label>
            <input type="tel" name="tel" id="tel"
                   formControlName="tel" pattern="^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$"
                   class="form-control" required>
            <div class="text-danger p-0"
                 *ngIf="!contactForm.controls['tel'].valid && contactForm.controls['tel'].touched">
              <span *ngIf="contactForm.controls['tel'].errors?.required">Please enter your Phone Number.</span>
              <span *ngIf="contactForm.controls['tel'].errors?.pattern">Please enter valid Phone Number.</span>
            </div>
          </div>
          <div class="form-group">
            <label for="message" class="text-all-dark">Message</label>
            <textarea required
                      name="message" formControlName="message"
                      id="message" cols="30" rows="5"
                      class="form-control"></textarea>
            <div class="text-danger p-0"
                 *ngIf="!contactForm.controls['message'].valid && contactForm.controls['message'].touched">
              <span *ngIf="contactForm.controls['message'].errors?.required">Please describe your Message.</span>
            </div>
          </div>
          <div class="form-group">
            <button type="submit"
                    [ngClass]="{'disabled': !contactForm.valid || formLoading}"
                    [disabled]="!contactForm.valid || formLoading"
                    class="btn btn-primary btn-lg">
              {{formLoading? 'loading...' : 'Submit'}}
            </button>
          </div>
        </form>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-12 my-3 py-4 bg-light rounded border">
        <h2 class="text-all-dark my-0">Where to Find Us:</h2>
        <hr>
        <h3 class="text-all-dark text-400">
          {{ contact.address.street }} <br>
          {{ contact.address.city }}, {{ contact.address.state }}, {{ contact.address.zip }} <br>
          {{ contact.phone }} <br>
          {{ contact.email.primary }}
        </h3>
      </div>
    </div>
  </div>
</section>

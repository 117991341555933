import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  baseUrl = 'https://rajasweets.com';
  constructor(private meta: Meta, private titleService: Title, @Inject(DOCUMENT) private doc) {}

  generateTags(tags) {
    // default values
    tags = {
      title : 'Maharaja Sweets - Authentic Indian cuisine and catering | Weddings,  Parties, Corporate Events',
      description: 'Specializing in authentic Indian fare, we cater mouthwatering dishes for small to large parties all over California. Our delicious menu features classics like our Tandoori Specials, to our Punjabi Specialities. Find out for yourself why over and over again countless people chose to have their special events Catered by us. The reasons are endless: Our fine cuisine, on site Tandoor for fresh nan, our Indian style unique decor stalls, our expertly trained chefs, friendly and knowledgeable staff, as well as our service.',
      keywords: 'Specializing in authentic Indian fare, we cater mouthwatering dishes for small to large parties all over California. Our delicious menu features classics like our Tandoori Specials, to our Punjabi Specialities. Find out for yourself why over and over again countless people chose to have their special events Catered by us. The reasons are endless: Our fine cuisine, on site Tandoor for fresh nan, our Indian style unique decor stalls, our expertly trained chefs, friendly and knowledgeable staff, as well as our service.',
      image: this.baseUrl + '/assets/featured-image.jpg',
      slug : '',
      ...tags
    };

    // Set a title
    this.titleService.setTitle(tags.title);

    // Set meta tags
    this.updateSocialMetaTags(tags);
  }

  updateSocialMetaTags(tags) {
    // Set social meta tags
    const url = this.baseUrl + `${tags.slug}`;

    const link: HTMLLinkElement = this.doc.querySelector('link[rel="canonical"]');
    link.setAttribute('href', url);

    this.meta.updateTag({ name: 'description', content: tags.description });
    this.meta.updateTag({ name: 'keywords', content: tags.keywords });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@Specializing in authentic Indian fare, we cater mouthwatering dishes for small to large parties all over California. Our delicious menu features classics like our Tandoori Specials, to our Punjabi Specialities. Find out for yourself why over and over again countless people chose to have their special events Catered by us. The reasons are endless: Our fine cuisine, on site Tandoor for fresh nan, our Indian style unique decor stalls, our expertly trained chefs, friendly and knowledgeable staff, as well as our servicerajasweets' });
    this.meta.updateTag({ name: 'twitter:title', content: tags.title });
    this.meta.updateTag({ name: 'twitter:description', content: tags.description });
    this.meta.updateTag({ name: 'twitter:image', content: tags.image });

    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Specializing in authentic Indian fare, we cater mouthwatering dishes for small to large parties all over California. Our delicious menu features classics like our Tandoori Specials, to our Punjabi Specialities. Find out for yourself why over and over again countless people chose to have their special events Catered by us. The reasons are endless: Our fine cuisine, on site Tandoor for fresh nan, our Indian style unique decor stalls, our expertly trained chefs, friendly and knowledgeable staff, as well as our servicerajasweets' });
    this.meta.updateTag({ property: 'og:title', content: tags.title });
    this.meta.updateTag({ property: 'og:description', content: tags.description });
    this.meta.updateTag({ property: 'og:image', content: tags.image });
    this.meta.updateTag({ property: 'og:url', content: url});
  }
}

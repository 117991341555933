import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';
import { SeoService } from '../../service/seo.service';

@Component({
  selector: 'rs-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  menu: any = [];

  constructor(private data: DataService, private seo: SeoService) {
    this.menu = this.data.getMenu();
    this.seo.generateTags({
      title: 'Menu | Maharaja Sweets - Authentic Indian cuisine and catering | Weddings,  Parties, Corporate Events',
      description: '',
      slug: '/menu'
    });
  }

  ngOnInit(): void {
  }

}

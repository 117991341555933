<section class="page-header py-3"
         style="background: url('../../../assets/about.jpg')">
  <div class="content">
    <h1 class="title">About Us</h1>
  </div>
</section>

<section class="container page-content">
  <div class="bg-white clearfix rounded p-3 shadow">
    <div class="row my-2">
      <div class="col-12">
        <h3>
          Maharaja Sweets has two locations in San Francisco Bay area that are serving over 28 years. We Specialize in Indian food catering for small to large groups any where in California. Find out for yourself why over and over again countless people chose to have their special events Catered by us. The reasons are endless: Our fine cuisine, on site Tandoor for fresh nan, our Indian style unique decor stalls, our expertly trained chefs, friendly and knowledgeable staff, as well as our service. Our owner's personal involvement and attention to details will help your special day be "unforgettable". Furthering our commitment to excellent service, quality, and convenience as one stop shopping. Our experienced staff will help to make your day a memorable one. <br><br>

          At Maharaja Sweets & Catering, we can provide you food, china & silverware, linen, waiters and we are fully licenced to serve hard liquor with full bar service anywhere in California. We want to make sure that you and your guests are well taken care of and treated in a courteous and professional manner. We want your guests to have best experience of their life when they come to your event. <br><br>

          Maharaja Sweets & Catering is confident that we have the experience to ensure your wedding, wedding reception, anniversary, birthday, bridal shower, baby shower, picnic, corporate events, meetings, religious gatherings, holidays and other parties are successful.
        </h3>
      </div>
    </div>
  </div>
</section>

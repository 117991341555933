<nav class="navbar navbar-expand-md fixed-top shadow navbar-light" id="header">
  <a class="navbar-brand" routerLink=""><!--
    <img
      src="../../assets/raja-sweets-logo.png" alt="Maharaja Sweets">-->
    <img
      src="../../../assets/maharaja-sweets-logo.png" alt="MahaMaharaja Sweets">
  </a>

  <button class="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#menuCollapse" aria-controls="menuCollapse"
          aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="menuCollapse">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" routerLink="">Home</a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="about">About</a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="menu">Menu</a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="catering">Catering</a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="contact">Contact</a>
      </li>
    </ul>
  </div>
</nav>

<section class="page-header py-3"
         style="background: url('../../../assets/about.jpg')">
  <div class="content">
    <h1 class="title">Catering</h1>
  </div>
</section>

<section class="container page-content">
  <div class="bg-white clearfix rounded p-3 shadow">
    <div class="row my-5" *ngIf="showConfirmMessage">
      <div class="col-12 text-center">
        <h1 class="text-primary mb-1">Thank you for your enquiry.</h1>
        <h2 class="text-tertiary">
          We will be in touch shorty!
        </h2>

        <a (click)="toggleCateringForm()" class="btn btn-primary btn-lg">Done</a>
      </div>
    </div>

    <div class="my-2" *ngIf="selectedPackage && !showConfirmMessage">
      <div class="row">
        <div class="col-12 text-left">
          <a (click)="toggleCateringForm()"
            class="btn btn-primary">Back</a>
        </div>
        <div class="col-12 text-center" id="cateringForm">
          <h1 class="font-weight-bolder text-secondary">
           <span [class]="selectedPackage.id">{{selectedPackage.name}}</span> is a Great Choice!
          </h1>

          <h3>At Maharaja Sweets & Catering, we can provide you food, china & silverware, linen, waiters and we are fully licenced to serve hard liquor with full bar service anywhere in California. We want to make sure that you and your guests are well taken care of and treated in a courteous and professional manner. We want your guests to have best experience of their life when they come to your event.</h3>
        </div>
      </div>

      <form [formGroup]="cateringForm" class="my-3"
        (ngSubmit)="submitCateringQuote(cateringForm.value)">

        <div class="row m-0">
          <div class="col-12 mb-2">
            <h1 class="text-primary">Tell us little bit about your event</h1>
            <hr class="border-secondary">
          </div>
        </div>

        <div class="row m-0">
          <div class="col-md-6 col-12">
            <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput type="text" name="name" id="name"
                     formControlName="name" placeholder="Your Name" required>
            </mat-form-field>
            <mat-error>
                <span *ngIf="!cateringForm.get('name').valid &&
                  cateringForm.get('name').touched">
                    <span *ngIf="cateringForm.get('name').errors?.required">
                      Please enter your name.
                    </span>
                </span>
            </mat-error>
          </div>

          <div class="col-md-6 col-12">
            <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="text" name="email" id="email"
                     formControlName="email" placeholder="Your Email" required>
            </mat-form-field>
            <mat-error>
                <span *ngIf="!cateringForm.get('email').valid &&
                  cateringForm.get('email').touched">
                    <span *ngIf="cateringForm.get('name').errors?.required">
                      Please enter your Email.
                    </span>
                    <span *ngIf="cateringForm.get('name').errors?.email">
                      Please enter valid Email.
                    </span>
                </span>
            </mat-error>
          </div>

          <div class="col-md-6 col-12">
            <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
              <mat-label>Phone</mat-label>
              <input type="tel" name="tel" id="tel" matInput
                     formControlName="tel" pattern="^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$"
                     required>
            </mat-form-field>
            <mat-error>
              <span *ngIf="!cateringForm.get('tel').valid &&
                cateringForm.get('tel').touched">
                  <span *ngIf="cateringForm.get('tel').errors?.required">
                    Please enter your Phone.
                  </span>
                  <span *ngIf="cateringForm.get('tel').errors?.pattern">
                    Please enter valid Phone.
                  </span>
              </span>
            </mat-error>
          </div>

          <div class="col-md-6 col-12">
            <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
              <mat-label>Number of people</mat-label>
              <input type="number" name="partySize" id="partySize" matInput
                     formControlName="partySize" pattern="^[0-9]*$"
                     required>
            </mat-form-field>
            <mat-error>
              <span *ngIf="!cateringForm.get('partySize').valid &&
                cateringForm.get('partySize').touched">
                  <span *ngIf="cateringForm.get('partySize').errors?.required">
                    Please enter number of people at your party.
                  </span>
                  <span *ngIf="cateringForm.get('partySize').errors?.pattern">
                    Please enter numbers only.
                  </span>
              </span>
            </mat-error>
          </div>

          <div class="col-md-6 col-12">
            <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
              <mat-label>Location</mat-label>
              <input type="text" name="location" id="location" matInput
                     formControlName="location" required>
            </mat-form-field>
            <mat-error>
              <span *ngIf="!cateringForm.get('location').valid &&
                cateringForm.get('location').touched">
                  <span *ngIf="cateringForm.get('location').errors?.required">
                    Please enter location of your party.
                  </span>
              </span>
            </mat-error>
          </div>


          <div class="col-12">
            <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
              <mat-label>Tell us little bit about your event.</mat-label>
              <textarea matInput
                        name="message" formControlName="message"
                        id="message" cols="30" rows="5"
                        class="form-control"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row m-0">
          <div class="col-12 my-2">
            <h1 class="text-primary">Appetizers</h1>
            <hr class="border-secondary">
          </div>
        </div>

        <div class="row m-0" *ngIf="selectedPackage.appetizers.veg > 0">
          <div class="col-12 m-0">
            <h3 class="my-0">Veg. ({{selectedPackage.appetizers.veg}})</h3>
          </div>

          <div formArrayName="vegAppetizer" class="col-md-6 col-12"
               *ngFor="let item of cateringForm.get('vegAppetizer')['controls'];
                 let i = index; let first = first">
            <div [formGroupName]="i">
              <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
                <mat-label>Veg. Appetizer</mat-label>
                <mat-select formControlName="name">
                  <mat-option *ngFor="let appetizer of autoComplete.vegAppetizers"
                              [value]="appetizer">
                    {{appetizer}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-error>
                <span *ngIf="!cateringForm.get('vegAppetizer')['controls'][i].controls.name.valid &&
                  cateringForm.get('vegAppetizer')['controls'][i].controls.name.touched">
                    <span *ngIf="cateringForm.get('vegAppetizer')['controls'][i].controls.name.errors?.required">
                      Please select an appetizer.
                    </span>
                </span>
              </mat-error>
            </div>
          </div>
        </div>

        <div class="row m-0" *ngIf="selectedPackage.appetizers.nonVeg > 0">
          <div class="col-12 mb-2">
            <h3 class="mt-3 mb-0">Non-Veg. ({{selectedPackage.appetizers.nonVeg}})</h3>
          </div>

          <div formArrayName="nonVegAppetizer" class="col-md-6 col-12"
               *ngFor="let item of cateringForm.get('nonVegAppetizer')['controls'];
                 let i = index; let first = first">
            <div [formGroupName]="i">
              <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
                <mat-label>Non-Veg</mat-label>
                <mat-select formControlName="name">
                  <mat-option *ngFor="let appetizer of autoComplete.nonVegAppetizers"
                              [value]="appetizer">
                    {{appetizer}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-error>
                <span *ngIf="!cateringForm.get('nonVegAppetizer')['controls'][i].controls.name.valid &&
                  cateringForm.get('nonVegAppetizer')['controls'][i].controls.name.touched">
                    <span *ngIf="cateringForm.get('nonVegAppetizer')['controls'][i].controls.name.errors?.required">
                        Please select an appetizer.
                    </span>
                </span>
              </mat-error>
            </div>
          </div>
        </div>

        <div class="row m-0" *ngIf="selectedPackage.appetizers.sweet > 0">
          <div class="col-12 mb-2">
            <h3 class="mt-3 mb-0">Sweet ({{selectedPackage.appetizers.sweet}})</h3>
          </div>

          <div formArrayName="sweetAppetizer" class="col-md-6 col-12"
               *ngFor="let item of cateringForm.get('sweetAppetizer')['controls'];
                 let i = index; let first = first">
            <div [formGroupName]="i">
              <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
                <mat-label>Sweet</mat-label>
                <mat-select formControlName="name">
                  <mat-option *ngFor="let appetizer of autoComplete.sweetAppetizers"
                              [value]="appetizer">
                    {{appetizer}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-error>
                <span *ngIf="!cateringForm.get('sweetAppetizer')['controls'][i].controls.name.valid &&
                  cateringForm.get('sweetAppetizer')['controls'][i].controls.name.touched">
                    <span *ngIf="cateringForm.get('sweetAppetizer')['controls'][i].controls.name.errors?.required">
                      Please select an appetizer.
                    </span>
                </span>
              </mat-error>
            </div>
          </div>
        </div>


        <div class="row m-0">
          <div class="col-12 my-2">
            <h1 class="text-primary">Entreés</h1>
            <hr class="border-secondary">
          </div>
        </div>

        <div class="row m-0" *ngIf="selectedPackage.entrees.veg > 0">
          <div class="col-12 mb-2">
            <h3 class="my-2">Veg. ({{selectedPackage.entrees.veg}})</h3>
          </div>

          <div formArrayName="vegEntree" class="col-md-6 col-12"
               *ngFor="let item of cateringForm.get('vegEntree')['controls'];
                 let i = index; let first = first">
            <div [formGroupName]="i">
              <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
                <mat-label>Veg.</mat-label>
                <mat-select formControlName="name">
                  <mat-option *ngFor="let entree of autoComplete.vegEntrees"
                              [value]="entree">
                    {{entree}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-error>
                <span *ngIf="!cateringForm.get('vegEntree')['controls'][i].controls.name.valid &&
                  cateringForm.get('vegEntree')['controls'][i].controls.name.touched">
                    <span *ngIf="cateringForm.get('vegEntree')['controls'][i].controls.name.errors?.required">
                      Please select an entree.
                    </span>
                </span>
              </mat-error>
            </div>
          </div>
        </div>

        <div class="row m-0" *ngIf="selectedPackage.entrees.nonVeg > 0">
          <div class="col-12 mb-2">
            <h3 class="mt-3 mb-0">Non-Veg. ({{selectedPackage.entrees.nonVeg}})</h3>
          </div>

          <div formArrayName="nonVegEntree" class="col-md-6 col-12"
               *ngFor="let item of cateringForm.get('nonVegEntree')['controls'];
                 let i = index; let first = first">
            <div [formGroupName]="i">
              <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
                <mat-label>Veg.</mat-label>
                <mat-select formControlName="name">
                  <mat-option *ngFor="let entree of autoComplete.nonVegEntrees"
                              [value]="entree">
                    {{entree}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-error>
                <span *ngIf="!cateringForm.get('nonVegEntree')['controls'][i].controls.name.valid &&
                  cateringForm.get('nonVegEntree')['controls'][i].controls.name.touched">
                    <span *ngIf="cateringForm.get('nonVegEntree')['controls'][i].controls.name.errors?.required">
                      Please select an entree.
                    </span>
                </span>
              </mat-error>
            </div>
          </div>
        </div>



        <div class="row m-0">
          <div class="col-12 my-2">
            <h1 class="text-primary">Dessert</h1>
            <hr class="border-secondary">
          </div>
        </div>

        <div class="row m-0" *ngIf="selectedPackage.dessert > 0">
          <div class="col-12 mb-2">
            <h3 class="mt-3 mb-0">Dessert ({{selectedPackage.dessert}})</h3>
          </div>

          <div class="col-md-6 col-12">
            <mat-form-field [floatLabel]="true" class="w-100" appearance="outline">
              <mat-label>Dessert</mat-label>
              <mat-select formControlName="dessert">
                <mat-option *ngFor="let dessert of autoComplete.desserts"
                            [value]="dessert">
                  {{dessert}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-error>
                <span *ngIf="!cateringForm.get('dessert').valid &&
                  cateringForm.get('dessert').touched">
                    <span *ngIf="cateringForm.get('dessert').errors?.required">
                      Please select a dessert.
                    </span>
                </span>
            </mat-error>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-12 text-center">
            <div class="alert text-center" *ngIf="notification.message"
                 [ngClass]="notification.class">
              {{ notification.message }}
            </div>
          </div>

          <div class="col-12 text-center">
            <button type="submit"
                    [ngClass]="{'disabled': !cateringForm.valid || formLoading}"
                    [disabled]="!cateringForm.valid || formLoading"
                    class="btn btn-primary btn-lg mx-2">
              {{formLoading? 'Submitting...' : 'Submit'}}
            </button>
            <button type="reset" (click)="toggleCateringForm()"
                    class="btn btn-primary-outline mx-1 btn-lg mx-2">Cancel</button>
          </div>
        </div>

      </form>
    </div>

    <div class="row my-2" *ngIf="!selectedPackage && !showConfirmMessage">
      <div class="col-md-6 col-12" *ngFor="let package of packages">
        <div class="package" [id]="package.id">
          <h2 class="title">{{ package.name }}</h2>

          <div class="metadata py-2">
            <h3 class="m-0 font-weight-bold sub-title">Appetizers</h3>
            <p class="item" *ngIf="package.appetizers.veg">{{package.appetizers.veg}} Choice of Veg. Snack</p>
            <p class="item" *ngIf="package.appetizers.nonVeg">{{package.appetizers.nonVeg}} Choice of Non-Veg. Snack</p>
            <p class="item" *ngIf="package.appetizers.sweet">{{package.appetizers.sweet}} Choice of Sweet</p>
          </div>

          <div class="metadata py-2">
            <h3 class="m-0 font-weight-bold sub-title">Main Course</h3>
            <p class="item" *ngIf="package.entrees.veg">{{package.entrees.veg}} Choice of Veg. Item</p>
            <p class="item" *ngIf="package.entrees.nonVeg">{{package.entrees.nonVeg}} Choice of Non-Veg. Item</p>
            <p class="item">Rice, Raita, Nan and Salad Included</p>
          </div>

          <div class="metadata py-2">
            <h3 class="m-0 font-weight-bold sub-title">Dessert</h3>
            <p class="item">{{package.dessert}} Choice of Dessert</p>
          </div>

          <a (click)="selectPackage(package)" class="btn btn-secondary btn-lg mb-3">Select</a>
        </div>
      </div>
    </div>
  </div>
</section>

import { Component, OnInit } from '@angular/core';
import { SeoService } from '../../service/seo.service';
import { DataService } from '../../service/data.service';

@Component({
  selector: 'rs-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  slides = [];
  contactInfo;

  constructor(private data: DataService, private seo: SeoService) {
    this.slides = this.data.getSlides();
    this.contactInfo = this.data.getContactInfo();
    this.seo.generateTags({
      title: 'Home | Maharaja Sweets - Authentic Indian cuisine and catering | Weddings,  Parties, Corporate Events',
      description: 'We are inspired by our clients. When it comes to our clients’ weddings, galas, events, parties, business and or corporate events we begin by either sitting down with them or talking with them on the phone, and we ask them about their vision for their special day.',
      slug: '/'
    });
  }

  ngOnInit(): void {
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../service/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'rs-catering',
  templateUrl: './catering.component.html',
  styleUrls: ['./catering.component.scss']
})
export class CateringComponent implements OnInit {
  formLoading = false;
  showConfirmMessage = false;
  notification = {
    message : '',
    class   : ''
  };
  autoComplete: any;
  packages;
  selectedPackage: any = false;
  cateringForm: FormGroup;

  constructor(private data: DataService, private fb: FormBuilder, private http: HttpClient) {
    this.packages = this.data.getCateringPackages();
    this.autoComplete = this.data.getCateringAutoComplete();
  }

  ngOnInit(): void {}

  toggleCateringForm() {
    this.selectedPackage = false;
    this.showConfirmMessage = false;
  }

  selectPackage(cateringPackage) {
    this.selectedPackage = cateringPackage;

    this.cateringForm = this.fb.group({
      packageName: [cateringPackage.name],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      tel: ['', [Validators.required]],
      partySize: ['', [Validators.required]],
      location: ['', [Validators.required]],
      message: [''],
      vegAppetizer: this.createFormItem('appetizer', 'veg', cateringPackage.appetizers.veg),
      nonVegAppetizer: this.createFormItem('appetizer', 'nonVeg', cateringPackage.appetizers.nonVeg),
      sweetAppetizer: this.createFormItem('appetizer', 'sweet', cateringPackage.appetizers.sweet),
      vegEntree: this.createFormItem('entrees', 'veg', cateringPackage.entrees.veg),
      nonVegEntree: this.createFormItem('entrees', 'nonVeg', cateringPackage.entrees.nonVeg),
      dessert: ['', [Validators.required]]
    });

    setTimeout(() => {
      document.querySelector('#cateringForm').scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 300);
  }

  submitCateringQuote(value) {
      if (this.cateringForm.valid) {
        console.log(value);
        this.formLoading = true;
        this.notification = {
          message : '',
          class   : ''
        };

        this.http.post('/api/v1/catering', value)
          .subscribe((res: any) => {
            if (res.success) {
              this.notification.message = 'Thank you for reaching out. We will contact you shortly!';
              this.notification.class = 'alert-success';

              this.cateringForm.markAsPristine();
              this.cateringForm.markAsUntouched();
              this.cateringForm.updateValueAndValidity();
              this.showConfirmMessage = true;
            } else {
              this.notification.message = 'Oops. Looks like something went wrong. Please try again one more time.';
              this.notification.class = 'alert-danger';
            }
            this.formLoading = false;
          });
      }
  }

  createFormItem(category, type, count) {
    const itemsArray = [];
    for (let i = 0; i < count; i++) {
      itemsArray.push(this.fb.group({
        name: ['', [Validators.required]]
      }));
    }

    return this.fb.array(itemsArray);
  }
}

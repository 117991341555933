<section id="home-slider" class="carousel slide" data-ride="carousel">
  <div class="slider-content">
    <div class="caption">
      <h1 class="title mb-0">Welcome to Maharaja Sweets & Indian Cuisine</h1>
      <p class="sub-heading">Serving community an Authentic Indian Flavors</p>
    </div>
  </div>

  <div class="carousel-inner">
    <div class="carousel-item"
         [style]="'background-image: url('+ slide +')'"
         [ngClass]="{'active': first}"
         *ngFor="let slide of slides; let first = first">
    </div>
  </div>
</section>

<section class="container-fluid bg-white py-4">
  <div class="container">
    <div class="row">
      <div class="col-12 text-tertiary py-4">
        <h1 class="mb-3 text-center font-weight-bold text-primary">
          We have been Masters in Catering for over 28 years!
        </h1>

        <h3 class="my-1">
          Specializing in authentic Indian fare, we cater mouthwatering dishes for small to large parties all over California. Our delicious menu features classics like our Tandoori Specials, to our Punjabi Specialities. Find out for yourself why over and over again countless people chose to have their special events Catered by us. The reasons are endless: Our fine cuisine, on site Tandoor for fresh nan, our Indian style unique decor stalls, our expertly trained chefs, friendly and knowledgeable staff, as well as our service. Our owner’s personal involvement and attention to details will help your special day be “unforgettable”. Furthering our commitment to excellent service, quality, and convenience as one stop shopping. Our experienced staff will help to make your day a memorable one.<br><br>

          At Maharaja Sweets & Catering, we can provide you food, china & silverware, linen, waiters and we are fully licenced to serve hard liquor with full bar service anywhere in California. We want to make sure that you and your guests are well taken care of and treated in a courteous and professional manner. We want your guests to have best experience of their life when they come to your event.<br><br>

          Maharaja Sweets & Catering is confident that we have the experience to ensure your wedding, wedding reception, anniversary, birthday, bridal shower, baby shower, picnic, corporate events, meetings, religious gatherings, holidays and other parties are successful.
        </h3>
      </div>
    </div>
  </div>
</section>

<!--
<section class="container-fluid">
  <div class="container p-0 my-4">
    <div class="row">
      <div class="col-md-6 col-12" *ngFor="let location of contactInfo.locations">
        <img class="my-2"
             [src]="location.logo"
             [alt]="location.name">
        <div class="metadata">
          <p class="mb-0">{{location.fullAddress}}</p>
          <p class="mb-0">{{location.phone}}</p>

          <ul class="list-unstyled">
            <li *ngFor="let hour of location.hours"
                  [innerHTML]="hour | safe: 'html'"></li>
          </ul>
        </div>

        <div>
          <iframe [src]="location.gmap | safe: 'resourceUrl'" width="100%" height="350" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
        </div>
      </div>
    </div>
  </div>
</section>-->

<footer class="footer">
  <div class="row text-white py-2">
    <div class="col-sm-6">
      <p class="m-0">Copyright 2020 · Maharaja Sweets & Indian Cuisine · All Rights Reserved</p>
    </div>
    <div class="col-sm-6">
      <p class="m-0">
        Developed by <a href="https://upsightly.com/" class="text-link primary">Upsightly</a>
      </p>
    </div>
  </div>
</footer>
